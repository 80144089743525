import React from 'react';

import { Layout } from '../components/layouts';
import {
  BlogPostSection,
  BlogPostSuggestSection,
} from '../components/sections';
import { Post } from '../types/post';

type Props = {
  pageContext: {
    post: Post;
  };
};

const BlogPostTemplate = ({ pageContext }: Props): JSX.Element => {
  const { post } = pageContext;

  return (
    <Layout>
      <BlogPostSection post={post} />
      <BlogPostSuggestSection post={post} />
    </Layout>
  );
};

export default BlogPostTemplate;
